<template>
  <div class="tabulation">
    <el-card class="chart-card">
      <!-- 搜索框 -->
      <div>
        <div class="grabble">
          <div style="width: 70%;display: flex;">
            <!-- 会员姓名搜索 -->
            <el-input
              class="hunt"
              v-model="designation"
              placeholder="请输入会员姓名"
              size="large"
              @input="orangutan"
			  clearable 
            />
            <!-- 会员编号搜索 -->
            <el-input
              class="hunt"
              v-model="serial"
              placeholder="请输入会员编码"
              size="large"
              @input="howl"
			  clearable 
            />
            <!-- 会员组织名称搜索 -->
            <el-select
              class="hunt"
              v-model="ability"
              placeholder="请选择组织名称"
              size="large"
              @click="multiple"
              clearable
              @change="numerical"
			  @focus="closeIt" 
			  ref="selectIt"
            />
            <!-- 是否达标 -->
            <el-select v-model="achieve" @change="fulfill" class="hunt" placeholder="请选择是否达标" clearable @clear="empty" size="large">
              <el-option label="是" value="1"  style="width:100%; text-align-last: center;" ></el-option>
              <el-option label="否" value="2" style="width:100%; text-align-last: center;"></el-option>
            </el-select>

            <!-- 月份范围 -->
            <el-date-picker
              v-model="value2"
              type="month"
              size="large"
              placeholder="请选择时间"
              @change="insuch"
            />

            <el-button style="margin-left: 10px" type="primary" size="large" @click="search1" :loading='formative'
              ><el-icon class="cancelgu"> <Search /> </el-icon>搜索</el-button
            >
          </div>
          <div>
            <el-button
              style="margin-left: 10px"
              type="primary"
              size="large"
              @click="standard=true"
              ><el-icon class="cancelgu"> <Setting /> </el-icon>设置达标</el-button
            >
            <el-button
              style="margin-left: 10px"
              type="primary"
              size="large"
              @click="append"
              :loading="ConfirmLoading" 
              :disabled='jinyong'
              ><img src="../assets/daochu.png" style="width:14px;margin-right: 6px" alt="">导出</el-button
            >
          </div>
        </div>
        <div class="statistics">
          统计：{{zong}}人
        </div>
      </div>
      <el-table
        :data="filterTableData"
        style="width: 100%"
        row-key="unionId"
        @cell-mouse-enter="obtain"
        border
        :header-cell-style="{ background: '#F5F7FA' }"
        :empty-text="texts"
      >
      <!-- 会员姓名 -->
        <el-table-column 
          align="center" 
          label="会员姓名" 
          prop="name"
          :show-overflow-tooltip='true'
        />
        <!-- 会员编号 -->
        <el-table-column
          label="会员编码"
          prop="username"
          align="center"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 所属工会 -->
        <el-table-column
          label="所属工会"
          prop="topUnionName"
          align="center"
          :formatter="quantum"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 所属部门 -->
        <el-table-column
          label="所属部门"
          prop="parentUnionName"
          align="center"
          :formatter="quantum"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 达标天数 -->
        <el-table-column
          label="达标天数"
          prop="standardDays"
          align="center"
          :formatter="quantum"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 是否达标 -->
        <el-table-column
          label="是否达标"
          prop="isStandard"
          align="center"
          :formatter="reach"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 本月总步数 -->
        <el-table-column
          label="本月总步数"
          prop="monthTotalSteps"
          align="center"
          :formatter="quantum"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <el-table-column label="操作" align="center" width="250" >
          <template #default="scope">
            <el-button
              size="default"
              @click="copyreader(scope.row.username)"
              class="bulur"
              ><el-icon class="cancel"> <Search /> </el-icon>历史查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 表格分页 -->
      <!-- 一页时候隐藏 hide-on-single-page="true" -->
      <el-pagination
        layout="prev, pager, next,sizes"
        hide-on-single-page="true"
        :page-sizes="[6, 12, 18, 24]"
        :current-page="currentPage" 
        :page-size="pagesize"
        :total="zong"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <!-- 删除按钮弹窗 -->
    <el-dialog v-model="remove" title="工会删除" width="30%" center>
      <div class="labor">
        <div class="sure">确定删除此工会吗？</div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="abrogate">取消</el-button>
          <el-button type="primary" @click="affirm">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 设置弹窗 -->
    <el-dialog v-model="standard" title="设置" width="30%" center close="install">
      <el-form
        ref="ruleFormRef"
        :model="form"
        :rules="rules"
        class="demo-ruleForm"
        :size="formSize"
        status-icon
      >
        <!-- 达标天数 -->
        <el-form-item label="达标天数" prop="name">
          <div class="aaaa">
            <el-input onkeyup="value=value.replace(/[^\d]/g,'')" v-model="form.name" type="number" placeholder="请填写达标天数" size="large"></el-input>
          </div>
        </el-form-item>
        <!-- 达标步数 -->
        <el-form-item label="达标步数" prop="breviary">
          <div class="aaaa">
            <el-input onkeyup="value=value.replace(/[^\d]/g,'')" v-model="form.breviary" type="number"  placeholder="请填写达标步数" size="large"></el-input>
          </div>
        </el-form-item>
      </el-form>
      <div class="default">
        提示：默认每日5000步并且满25天达标。
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button class="asd" size="large" :loading="upload" type="primary" @click="notarize">确定</el-button>
          <el-button class="dsa" size="large" @click="abrogate">取消</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 所属组织弹窗 -->
    <div>
      <s3-layer v-model="visible" title="所属组织" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
        <el-tree
          :data="texture"
          :props="defaultProps"
          accordion
          @node-click="dendrogram"
        />
      </s3-layer>
    </div>
    <!-- 导出提示 -->
    <div>
      <s3-layer v-model="anew" title="提示" :shadeClose="true" :area="['50px', '170px']" @yes="yes">
        <div class="task">
          {{quoth}}
        </div>
      </s3-layer>
    </div>
  </div>
</template>
<script>
import {ref,onMounted,reactive} from 'vue'
import { Search,Setting} from "@element-plus/icons-vue";
import { useRouter  } from "vue-router";
import { JBlist,Zzlist,JBset,JBsetting,JBexport } from "../utils/api"
import qs from 'qs'
import { ElMessage } from "element-plus";
export default {
  name:"walk",
  setup() {
    // 弹窗提示
    let quoth=ref('')
    let anew=ref(false)
    // 搜索加载
    const formative=ref(false)
    // 时间选择
    let value2=ref('')
	//隐藏下拉框
	let selectIt = ref()
	// 下拉隐藏
	let closeIt = () => {
		selectIt.value.blur();
	}
    // 取消按钮
    let abrogate=()=>{
      standard.value=false
      form.name=standardT.value
      form.breviary=standardB.value
    }
    // 导出按钮是否禁用
    let jinyong=ref(false)
    // 导出加载
    let ConfirmLoading=ref(false)
    // 加载中
    let texts = ref('加载中')
    // 导出按钮
    let append=()=>{
      let now = new Date();
      let nowYear = now.getFullYear(); //当前年
      let nowMonth = now.getMonth();//当前月
      let firstDay = new Date(nowYear, nowMonth, 1).getTime()
      let itemss=ref('')
      if(value2.value!==''&&value2.value!==null){
        itemss.value=value2.value.getTime()
      }else{
        itemss.value=firstDay
      }
      jinyong.value=true
      ConfirmLoading.value=true
      let data ={
        name:designation.value,
        username:serial.value,
        unionCode:Hcode.value,
        isStandard:achieve.value,
        startTime:itemss.value/1000
      }
      JBexport(qs.stringify(data)).then((res)=>{
        console.log(res)
        if(res.data.code==0){
          anew.value=true
          quoth.value='任务已提交，请在右侧查看进度'
          jinyong.value=false
          ConfirmLoading.value=false
          // window.open(res.data.data.excelUrl)
        }else{
          jinyong.value=false
          ConfirmLoading.value=false
          ElMessage({
            message: res.data.msg,
            type: "error",
            center: true,
          });
        }
      })
      // console.log('会员姓名',designation.value)
      // console.log('会员编码',serial.value)
      // console.log('组织code',Hcode.value)
      // console.log('是否达标',achieve.value)
    }
    // 设置按钮 提交加载
    let upload=ref(false)
    // 设置弹窗确认
    const notarize=()=>{
      ruleFormRef.value.validate((valid)=>{
        if(valid==true){
          upload.value=true
          let data = {
            standardSteps:form.breviary,
            standardDays:form.name
          }
          JBsetting(qs.parse(data)).then((res)=>{
            console.log(res)
            if(res.data.code==0){
              ElMessage({
                message: "设置成功",
                type: "success",
                center: true,
              });
              standard.value=false
              upload.value=false
            }else{
              upload.value=false
            }
          })
        }else{
          upload.value=false
        }
        console.log(valid)
        console.log('天数',form.name)
        standardT.value=form.name
        console.log('步数',form.breviary)
        standardB.value=form.breviary
      })
    }
    // 标题验证
    const ruleFormRef = ref(null)
    const form = reactive({
      name:"",
      breviary:"",
    })
    // 验证表单提示
    const rules ={
      breviary:[
        { required: true, message: "请填写达标步数", trigger: "change" },
      ],
      name:[
        { required: true, message: "请填写达标天数", trigger: "change" },
      ],
    }
    // 当前达标天数
    let standardT=ref('')
    // 当前达标步数
    let standardB=ref('')
    // 获取当前设置信息
    let tallation=()=>{
      JBset().then((res)=>{
        if(res.data.code==0){
          standardB.value=res.data.data.walkPowerInfo.standardSteps
          standardT.value=res.data.data.walkPowerInfo.standardDays
          form.name=res.data.data.walkPowerInfo.standardDays
          form.breviary=res.data.data.walkPowerInfo.standardSteps
        }
      })
    }
    // 是否达标搜索框变化
    // 月份变化
    let asdf=ref('')
    // 是否达标
    // let fulfill=()=>{
    //   console.log(ability.value)
    //   console.log(designation.value)
    //   console.log(serial.value)
    //   console.log(achieve.value)
    //   console.log(asdf.value)
    //   if(designation.value==''&&serial.value==''&&ability.value==''&&achieve.value==''&&asdf.value==''||asdf.value==null){
    //     list()
    //   }
    // }
    // 选择组织搜索框变化
    let numerical=()=>{
      // if(designation.value==''&&serial.value==''&&ability.value==''&&achieve.value==''&&asdf.value==''||asdf.value==null){
      //   list()
      // }
      Hcode.value=''
    }
    // 会员编号搜索框变化
    // let howl=()=>{
    //   if(designation.value==''&&serial.value==''&&ability.value==''&&achieve.value==''&&asdf.value==''||asdf.value==null){
    //     list()
    //   }
    // }
    // 月份变化
    // let insuch=(e)=>{
    //   asdf.value=e
    //   if(designation.value==''&&serial.value==''&&ability.value==''&&achieve.value==''&&asdf.value==null||asdf.value==null){
    //     list()
    //   }
    // }
    // 姓名搜索框变化
    // let orangutan=()=>{
    //   if(designation.value==''&&serial.value==''&&ability.value==''&&achieve.value==''&&asdf.value==''||asdf.value==null){
    //     list()
    //     console.log('空了')
    //   }
    // }
    // 是否达标选择值
    let achieve=ref('')
    // 获取code 
    let Hcode=ref('')
    // 搜索组织选择值
    let ability=ref('')
    // 弹窗树形选择
    let dendrogram = (node)=>{
      ability.value=node.unionName
      Hcode.value=node.unionCode
      console.log(node)
    }
    // 弹窗组织列表树形
    let texture =ref([])
    const defaultProps = {
      children: 'unionDtoList',
      label: 'unionName',
    }
    // 搜索组织列表接口
    const lang=()=>{
      Zzlist().then((res)=>{
        console.log(res)
        texture.value=res.data.data.wholeUnionList
      })
    }
    // 选择组织按钮
    let multiple =()=>{
      visible.value=true
    }
    // 搜索组织弹窗
    let visible=ref(false)
    // 顶部搜索
    let itemss=ref('')
    let search1=()=>{
      formative.value=true
      search()
    }
    let search=()=>{
      let now = new Date();
      let nowYear = now.getFullYear(); //当前年
      let nowMonth = now.getMonth();//当前月
      let firstDay = new Date(nowYear, nowMonth, 1).getTime()
      if(value2.value!==''&&value2.value!==null){
        itemss.value=value2.value.getTime()
      }else{
        itemss.value=firstDay
      }
      console.log(itemss.value)
      let data={
        limit:branches.value,
        page:currentPage.value,
        name:designation.value,
        username:serial.value,
        unionCode:Hcode.value,
        isStandard:achieve.value,
        startTime:itemss.value/1000
      }
      JBlist(qs.stringify(data)).then((res)=>{
        formative.value=false
        console.log(res)
        zong.value=res.data.data.page.totalCount
        filterTableData.value=res.data.data.page.list
        if(filterTableData.value.length==0){
            texts.value='暂无数据'
        }
      })
      // console.log('会员姓名',designation.value)
      // console.log('会员编码',serial.value)
      // console.log('组织code',Hcode.value)
      // console.log('是否达标',achieve.value)
    }
    // 会员编码
    let serial=ref('')
    // 会员名称
    let designation =ref('')
    // 分页
    let currentPage=ref(1)
    let pagesize=ref(6)
    let handleSizeChange=(size)=>{
      pagesize.value=size
      branches.value=size
      if(designation.value!==''||serial.value!==''||ability.value!==''||achieve.value!==''||itemss.value!==''){
        search()
      }else{
        list()
      }
    }
    let handleCurrentChange=(size)=>{
      console.log(size)
      currentPage.value=size
      texts.value='加载中'
      if(designation.value!==''||serial.value!==''||ability.value!==''||achieve.value!==''||itemss.value!==''){
        search()
      }else{
        list()
      }
    }
    // 列表是否达标
    let reach=(row, column, cellValue)=>{
      if (cellValue == 1) {
        return "是";
      } else if (cellValue === 2) {
        return "否";
      }
    }
    onMounted(()=>{
      list()
      lang()
      setTimeout(()=>{
        tallation()
      },300)
    })
    // 动态加载数据
    let branches=ref(6)
    // 数据总共条数
    let zong =ref(null)
    // 运动健步列表接口
    let list =()=>{
      let data={
        limit:branches.value,
        page:currentPage.value
      }
      JBlist(qs.stringify(data)).then((res)=>{
        console.log(res)
        if(res.data.code==0){
          zong.value=res.data.data.page.totalCount
          filterTableData.value=res.data.data.page.list
          if(filterTableData.value.length==0){
            texts.value='暂无数据'
          }
        }
      })
    }
    // 设置弹窗关闭
    let install=()=>{
      console.log('关闭了')
    }
    // 设置按钮弹窗
    let standard =ref(false)
    // 路由跳转
    const router = useRouter()
    // 查看跳转
    const copyreader =(id)=>{
      sessionStorage.setItem('walking', id)
       router.push({ name: 'jbedit', params: { UserId: 1 }});
      // router.push('/jbedit')
      console.log(id)
    }
    // 列表数据循环
    const filterTableData = ref([])
    return {
      anew,
      quoth,
      // 搜索加载
      search1,
      formative,
      // 月份变化
      asdf,
      // insuch,
      // 时间选择
      value2,
      // 取消按钮
      abrogate,
      // 导出加载
      jinyong,
      ConfirmLoading,
      texts,
      // 导出按钮
      append,
      // 提交按钮设置
      upload,
      // 设置弹窗确认
      notarize,
      // 标题验证
      ruleFormRef,
      form,
      rules,
      // 是否达标搜索框变化
      // fulfill,
      // 选择组织搜索框变化
      numerical,
      // 会员编码搜索框变化
      // howl,
      // 姓名搜索框变化
      // orangutan,
      // 是否达标
      achieve,
      // 树形选择值
      ability,
      // 搜索弹窗树形
      dendrogram,
      defaultProps,
      texture,
      // 选择组织按钮
      multiple,
      // 搜索组织弹窗
      visible,
      // 会员编码
      serial,
      // 顶部搜索
      search,
      // 会员名称
      designation,
      // 分页
      zong,
      pagesize,
      currentPage,
      handleSizeChange,
      handleCurrentChange,
      // 是否达标
      reach,
      // 弹窗关闭
      install,
      // 查看跳转
      copyreader,
      // 设置按钮弹窗
      standard,
      // 循环列表数据
      filterTableData,
	  closeIt,
	  selectIt,
    };
  },
  components: {
    Setting,
    Search,
  },
};
</script>
<style lang="scss" scoped>
::v-deep .dsa{
  margin-left: 10px !important;
}
::v-deep .asd{
  margin-right: 10px !important;
}
::v-deep .chart-card::-webkit-scrollbar{
  display:none
}
.chart-card{
  height: calc(100vh - 145px);
  overflow: auto;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button { 
    -webkit-appearance: none !important; 
}
::v-deep  .el-table__empty-block{
  border-bottom: 1px solid #EBEEF5 !important;
}
.el-button+.el-button{
  margin-left: 0px;
}
.default{
  color: #909399;
  margin-left: 10px;
}
.designation {
  font-size: 15px;
  font-weight: bold;
}
.statistics{
  font-size: 14px;
  color: #909399;
  margin-bottom: 15px;
}
::v-deep .el-table__body {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
::v-deep .el-table__header {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.hunt {
  width: 25%;
  margin-right: 10px;
}
.grabble {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}
.el-table__header {
  margin-top: 0;
  margin-bottom: 0;
}
.bulur {
  color: #409eff;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
}
.bulur:hover {
  color: #a0cfff;
}
// .el-button{
//   border: none;
//   --el-button-bg-color:none;
//   --el-button-hover-bg-color:none;
//   --el-button-hover-text-color:none;
// }
.el-scrollbar__thumb {
  background-color: #ffffff;
}
.items {
  display: flex;
}
.el-select .el-input__inner {
  width: 183px;
}
::v-deep .el-dialog--center .el-dialog__body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .el-select-dropdown__empty {
  display: none !important  ;
}
.el-select-dropdown__item {
  background-color: #ffffff;
  height: 40px !important;
}
.el-select-dropdown__item {
  overflow: visible;
}
.labor {
  display: flex;
  align-items: center;
  // justify-content: center;
  margin-bottom: 10px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tianjia {
  margin-left: 20px;
}
.cancel {
  margin-right: 5px;
  font-weight: 400;
}
.cancelgu {
  margin-right: 6px;
  font-weight: bold;
}
::v-deep .el-table__inner-wrapper::before {
  width: 0;
}
::v-deep .el-table .el-table__cell {
  padding-top: 15px;
  padding-bottom: 15px;
}
.el-card.is-always-shadow {
  width: 95%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
::v-deep .el-card__body {
  padding: 0;
}
.el-pagination {
  margin-top: 10px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tabulation {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
::v-deep .el-scrollbar__thumb {
  display: none !important;
}
</style>
<style>
/* ::-webkit-scrollbar{display:none} */
.el-popper {
  z-index: 10000 !important;
}
.modality {
  height: 150px !important;
}
/* .el-scrollbar__thumb {
  display: none !important;
} */
.el-select-dropdown__item.selected {
  font-weight: 500 !important;
}
</style>
